 <template>
    <a-layout id="components-layout-demo-custom-trigger">
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
            <div class="logo">
                <img class="img" src="@/assets/images/logo.png" alt="">
                <div class="size" v-show="!collapsed">申怡伴读管理后台</div>
            </div>
            <a-menu :defaultOpenKeys="['1']" :defaultSelectedKeys="['home']" :selectedKeys="selectedKeys" mode="inline" :open-keys="openKeys" theme="light" @openChange="onOpenChange" @click="menuClick">
                <a-menu-item key="home" @click="() => $router.push('/home')">
                    <div style="display: flex;">
                        <span class="imgBack"></span>                            
                        <span>首页</span>
                    </div>
                </a-menu-item>
                <a-sub-menu  v-for="(item,index) in powerArr" :key="item.code">
                    <span slot="title">
                        <div style="display: flex;">
                            <span v-if="index == 0" class="imgBack0"></span>
                            <span v-if="index == 1" class="imgBack1"></span>
                            <span v-if="index == 2" class="imgBack2"></span>
                            <span v-if="index == 3" class="imgBack3"></span>
                            <span v-if="index == 4" class="imgBack4"></span>
                            <span v-if="index == 5" class="imgBack5"></span>
                            <span v-if="index == 6" class="imgBack6"></span>
                            <span>{{ item.name }}</span> 
                        </div>
                    </span >
                    <a-menu-item :key="e.code" v-for="e in item.children">
                        <router-link :to="e.url">{{ e.name }}</router-link>
                    </a-menu-item>
                </a-sub-menu>
            </a-menu>
        </a-layout-sider>
        <a-layout>
            <a-layout-header style="background: #fff; padding: 0">
                <!-- <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)"/> -->
                <!-- 面包屑 -->
                <a-breadcrumb separator=">" class="flex1" style="padding-left:18px">
                    <a-breadcrumb-item v-for="(item, index) of $route.matched" :key="index" >
                        <span style="font-size: 16px;" @click="breadcrumbClick(item, index)" >{{ item.meta.title }}</span>
                    </a-breadcrumb-item>
                </a-breadcrumb>
                <span class="login_out">
                    <div>
                        <a-dropdown>
                            <span class="ant-dropdown-link" @click="(e) => e.preventDefault()" >
                                <!-- <img :src="headUrl" alt="头像" /> -->
                                {{ userName || '暂无名称' }}
                            </span>
                            <a-menu slot="overlay" @click="loginOut">
                                <a-menu-item key="1"> 退出登录 </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </span>
            </a-layout-header>
            <!-- 内容区域 -->
            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }" >
                <router-view />
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
import { UserLogoutApi } from "@/request/api/login";
export default {
    created() {
        this.selectedKeys.push(this.$route.path.split("/")[2]);
        this.openKeys = [];
        this.openKeys.push(this.$route.path.split("/")[1]);
        // 处理侧栏收起状态
        this.$watch("collapsed", () => {
            this.$store.commit("sidebarType", this.collapsed);
        });
        this.userName = JSON.parse(localStorage.getItem("user")).adminName // 设置用户名
        // this.headUrl = localStorage.getItem("headUrl"); // 设置头像
        // 权限数组
        this.powerArr = JSON.parse(localStorage.getItem("powerListArr"));
    },
    data() {
        return {
            collapsed: false, // 侧边导航栏是否展开
            userName: "",
            headUrl: "",
            rootSubmenuKeys: [
                "systemManage",
                "rolePower",
                "agenManage",
                "costManage",
                "orderManage",
                "personnelManage",
                "profitManage",
            ],
            selectedKeys: [], // 当前选中的menu-item的key数组
            openKeys: ["rolePower"], // 展开的 sub-menu 的 key 数组
            // 权限数组
            powerArr: [
                {
                    code: "systemManage",
                    title: "系统管理",
                    icon: "solution",
                    children: [
                        {
                            code: "menuManage",
                            title: "菜单管理",
                            url: "/systemManage/menuManage",
                        },
                    ],
                },
                {
                    code: "rolePower",
                    title: "角色和权限",
                    icon: "container",
                    children: [
                        {
                            code: "roleManage",
                            title: "角色管理",
                            url: "/rolePower/roleManage",
                        },
                        {
                            code: "personManage",
                            title: "人员管理",
                            url: "/rolePower/personManage",
                        },
                    ],
                },
                {
                    code: "agenManage",
                    title: "代理管理",
                    icon: "solution",
                    children: [
                        {
                            code: "upertubeManage",
                            title: "菜单管理",
                            url: "/agenManage/upertubeManage",
                        },
                    ],
                },
                {
                    code: "costManage",
                    title: "成本管理",
                    icon: "solution",
                    children: [
                        {
                            code: "setupManage",
                            title: "成本设置",
                            url: "/costManage/setupManage",
                        },
                    ],
                },
                {
                    code: "orderManage",
                    title: "订单管理",
                    icon: "warning",
                    children: [
                        {
                            code: "orderList",
                            title: "订单列表",
                            url: "/orderManage/orderList",
                        },
                        {
                            code: "withdrawal",
                            title: "提现申请",
                            url: "/orderManage/withdrawal",
                        },
                    ],
                },
                {
                    code: "personnelManage",
                    title: "用户管理",
                    icon: "solution",
                    children: [
                        {
                            code: "userList",
                            title: "用户列表",
                            url: "/personnelManage/userList",
                        },
                    ],
                },
                {
                    code: "profitManage",
                    title: "收益",
                    icon: "solution",
                    children: [
                        {
                            code: "incomeDetails",
                            title: "收益明细",
                            url: "/profitManage/incomeDetails",
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        // 面包屑的按钮
        breadcrumbClick(item, index) {
            if (index != 0) {
                this.$router.push(item.path);
            }
        },
        // 切换
        toggleCollapsed() {
            this.collapsed = !this.collapsed;
        },
        // SubMenu 展开/关闭的回调
        onOpenChange (openKeys) {
            const latestOpenKey = openKeys.find((key) => this.openKeys.indexOf(key) === -1);
            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                this.openKeys = openKeys;
            } else {
                this.openKeys = latestOpenKey ? [latestOpenKey] : [];
            }
        },
        menuClick (item) {
            this.selectedKeys = []; // 清空选中的子菜单的key 数组
            this.selectedKeys.push(item.key);
        },
        // 退出登录
        loginOut() {
            UserLogoutApi().then(({ code }) => {
                if (code === 200) {
                    localStorage.clear();
                    this.$router.push("/login");
                }
            });
        },
    },
};
</script>
<style  lang="less" scoped>
.imgBack{
    display: block;
    width: 24px;
    height: 24px;
    background: url("./../assets/images/icon_home.png");
    background-size: 100% 100%;
    margin-right: 14px;
    margin-top: 5px;
}
.ant-menu-item:hover{
    color: #FF8200;
    .imgBack{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_homec.png");
        background-size: 100% 100%;
        margin-right: 14px;
        margin-top: 5px;
    }
}
.ant-menu-item-selected{
    .imgBack{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_homec.png");
        background-size: 100% 100%;
        margin-right: 14px;
        margin-top: 5px;
    }
}
.imgBack0{
    display: block;
    width: 24px;
    height: 24px;
    background: url("./../assets/images/icon_xt.png");
    background-size: 100% 100%;
    margin-right: 14px;
    margin-top: 5px;
}
.imgBack1{
    display: block;
    width: 24px;
    height: 24px;
    background: url("./../assets/images/icon_qx.png");
    background-size: 100% 100%;
    margin-right: 14px;
    margin-top: 5px;
}
.imgBack2{
    display: block;
    width: 24px;
    height: 24px;
    background: url("./../assets/images/icon_dl.png");
    background-size: 100% 100%;
    margin-right: 14px;
    margin-top: 5px;
}
.imgBack3{
    display: block;
    width: 24px;
    height: 24px;
    background: url("./../assets/images/icon_cb.png");
    background-size: 100% 100%;
    margin-right: 14px;
    margin-top: 5px;
}
.imgBack4{
    display: block;
    width: 24px;
    height: 24px;
    background: url("./../assets/images/icon_dd.png");
    background-size: 100% 100%;
    margin-right: 14px;
    margin-top: 5px;
}
.imgBack5{
    display: block;
    width: 24px;
    height: 24px;
    background: url("./../assets/images/icon_yh.png");
    background-size: 100% 100%;
    margin-right: 14px;
    margin-top: 5px;
}
.imgBack6{
    display: block;
    width: 24px;
    height: 24px;
    background: url("./../assets/images/icon_sy.png");
    background-size: 100% 100%;
    margin-right: 14px;
    margin-top: 5px;
}
.ant-menu-submenu-selected{
  color: #FF8200;
  .imgBack0{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_xtc.png");
        background-size: 100% 100%;
    }
    .imgBack1{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_qxc.png");
        background-size: 100% 100%;
    }
    .imgBack2{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_dlc.png");
        background-size: 100% 100%;
    }
    .imgBack3{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_cbc.png");
        background-size: 100% 100%;
    }
    .imgBack4{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_ddc.png");
        background-size: 100% 100%;
    }
    .imgBack5{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_yhc.png");
        background-size: 100% 100%;
    }
    .imgBack6{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_syc.png");
        background-size: 100% 100%;
    }
}
a{
    transition:none
}
.ant-menu-item:active, .ant-menu-submenu-title:active{
    background: #FFF3E6;
}
.ant-menu-submenu, .ant-menu-submenu-inline{
    transition:none
}
/deep/.ant-menu-submenu-title:hover{
    color: #FF8200 !important;
    transition: none;
    .imgBack0{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_xtc.png");
        background-size: 100% 100%;
    }
    .imgBack1{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_qxc.png");
        background-size: 100% 100%;
    }
    .imgBack2{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_dlc.png");
        background-size: 100% 100%;
    }
    .imgBack3{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_cbc.png");
        background-size: 100% 100%;
    }
    .imgBack4{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_ddc.png");
        background-size: 100% 100%;
    }
    .imgBack5{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_yhc.png");
        background-size: 100% 100%;
    }
    .imgBack6{
        display: block;
        width: 24px;
        height: 24px;
        background: url("./../assets/images/icon_syc.png");
        background-size: 100% 100%;
    }
}
// 退出登录
.login_out {
  margin-right: 24px;
  div {
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .ant-dropdown-trigger {
      cursor: pointer;
    }
  }
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  text-align: center;
}
.ant-layout.ant-layout-has-sider {
  height: 100vh;
}

.ant-layout-header {
  display: flex;
  align-items: center;
}
.ant-layout-content {
  padding: 0 !important;
  position: relative;
  background-color: #f0f2f5 !important;
  overflow-y: scroll;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  // line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0 26px;
  .img{
    width: 44px;
    height: 44px;
  }
  .size{
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
}
.ant-layout-sider{
  background-color: #fff;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border-right: 0;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background: linear-gradient(270deg, rgba(255, 130, 0, 0.2) 0%, rgba(255, 130, 0, 0) 100%);
}
.ant-menu-item-selected > a, .ant-menu-item-selected > a:hover{
  color: #FF8200;
}
.ant-menu-item > a:hover{
  color: #FF8200;
}
/deep/.ant-menu-item, .ant-menu-submenu-title{
    transition: none;
}
.ant-menu-item-selected{
  color: #FF8200;
}
.ant-breadcrumb > span:last-children{
  color: #FF8200;
}
/deep/.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #FF8200, #FF8200);
}
/deep/.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
  background: linear-gradient(to right, #FF8200, #FF8200);
}
.ant-menu-submenu-title .anticon{
  margin-right: 12px;
  font-size: 24px;
}
.anticon{
  vertical-align: -0.25em;
}
.anticon-home{
  margin-right: 14px;
  font-size: 24px;
}
/deep/.ant-menu-inline{
  border-right: 0;
}
.ant-menu{
  color: #000;
}

/deep/.ant-breadcrumb {
  cursor: pointer;
}
</style>